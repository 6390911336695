<template>
  <div class="height-100 max_box">
    <div class="audio-box">
      <audio src="audio/AVeryHappyChristmas.mp3" ref="bgAudio"></audio>
      <audio src="audio/huaan1.mp3" ref="audio1"></audio>
      <audio src="audio/huaan2.mp3" ref="audio2"></audio>
      <audio src="audio/huaan2_1.mp3" ref="audio2_1"></audio>
      <audio src="audio/huaan3_2.mp3" ref="audio3_2"></audio>
      <audio src="audio/huaan3.mp3" ref="audio3"></audio>
      <audio src="audio/huaan4.mp3" ref="audio4"></audio>
      <audio src="audio/huaan5.mp3" ref="audio5"></audio>
      <audio src="audio/huaan6.mp3" ref="audio6"></audio>
      <audio src="audio/huaan7.mp3" ref="audio7"></audio>
      <audio src="audio/huaan8.mp3" ref="audio8"></audio>
      <audio src="audio/huaan9.mp3" ref="audio9"></audio>
      <audio src="audio/huaan10.mp3" ref="audio10"></audio>    
      <audio src="audio/huaan11.mp3" ref="audio11"></audio>
      <audio src="audio/huaan12.mp3" ref="audio12"></audio>
    </div>
    <div class="card_heng animate__animated animate__fadeIn duration " id="card1_heng">
      <div class="card1_top_box">
        <p class="font-blod">华安沪港深外延增长混合</p>
        <p>001694</p>
        <div class="flex">
          <div>
            <p>成立日期</p>
            <p>2016-03-09</p>
          </div>
          <div>
            <p>基金类型</p>
            <p>灵活配置型</p>
          </div>
          <div>
            <p>风险等级</p>
            <p>中高风险</p>
          </div>
        </div>
      </div>
      <div class="tips">本视频不作为基金推介宣传材料</div>
    </div>
    <div class="card_heng animate__animated animate__fadeIn duration none" id="card2">
      <div class="card_main">
        <div class="title">
          <p class="title_l">收益走势</p>
          <p class="title_r">
            <span>华安沪港深外延增长混合</span><span>沪深300</span>
          </p>
        </div>
        <div class="content_box relative">
          <div id="chart1" class="chart"></div>
          <div class="ab_center content_box_p none">
            <p><span>5年</span>净值增长</p>
            <p><span class="large">3.19</span>倍</p>
          </div>
        </div>
      </div>
      <div class="tips">本视频不作为基金推介宣传材料</div>
    </div>
    <div class="card_heng animate__animated animate__fadeIn duration none" id="card3">
      <div class="card_main card3_cardmain">
        <div class="title">
          <p class="title_l">业绩表现</p>
        </div>
        <div class="content_box relative">
          <div id="chart2" class="chart"></div>
          <div class="ab_center none card3_abcenter relative">
            <p><span>近5年年化回报在同类型基金中排名</span></p>
            <p class="color_EF622E"><span class="large">TOP2</span></p>
          </div>
          <div class="card3_des">
            <div class="font-16 title_des1 none">
              <span>同期同类<br>年化回报</span><span class="color_EF622E card3_num">13.8%</span>
            </div>
            <div class="font-16 title_des2 none">
              <span>本基金年化回报</span><span class="color_EF622E card3_num">33.2%</span>
            </div>
            <div class="font-16 title_des3 none">
              <span>超过同类平均</span><span class="color_EF622E card3_num">19.4%</span>
            </div>
          </div>
        </div>
      </div>
      <div class="tips">本视频不作为基金推介宣传材料</div>
    </div>
    <div class="card_heng animate__animated animate__fadeIn duration none" id="card4">
      <div class="card_main">
        <div class="content_box relative">
          <div class="move_1">
            <p>这只产品究竟怎么样?</p>
          </div>
        </div>
      </div>
      <div class="tips">本视频不作为基金推介宣传材料</div>
    </div>
    <div class="card_heng animate__animated animate__fadeIn duration none" id="card5">
      <div class="card_main">
        <div class="title">
          <p class="title_l">基金收益能力</p>
        </div>
        <div class="content_box flex">
           <div>
             <p>近3年收益率</p>
             <p><span id="num2">307.98</span>%</p>
           </div>
           <div>
             <p>同类基金对比</p>
             <p><span class="compare">超</span><span>98</span>%</p>
           </div>
        </div>
      </div>
      <div class="tips">本视频不作为基金推介宣传材料</div>
    </div>
    <div class="card_heng animate__animated animate__fadeIn duration none" id="card6">
      <div class="card_main">
        <div class="title">
          <p class="title_l">基金持仓情况</p>
        </div>
        <div class="content_box relative">
          <div id="chart3" class="chart"></div>
        </div>
      </div>
      <div class="tips">本视频不作为基金推介宣传材料</div>
    </div>
    <div class="card_heng animate__animated animate__fadeIn duration none" id="card7">
      <div class="card_main">
        <div class="content_box relative">
          <div class="move_3">
            <p>基金经理表现如何?</p>
          </div>
        </div>
      </div>
      <div class="tips">本视频不作为基金推介宣传材料</div>
    </div>
    <div class="card_heng animate__animated animate__fadeIn duration none" id="card8">
      <div class="card_main">
        <div class="title">
          <p class="title_l">基金经理</p>
        </div>
        <div class="content_box">
          <div class="flex align-center">
            <p><img src="../assets/img/p1.png" alt="" /></p>
            <div class="flex1">
              <p class="flex flex-between font-24 margin-l-8 name_box">
                <span>崔莹</span><span><i id="num1"></i>%</span>
              </p>
              <div class="flex flex-between margin-l-8 des_box">
                <p>
                  <span>硕士</span>
                  <span class="margin-l-8 margin-r-8">|</span>
                  <span>华安基金</span>
                </p>
                <p>主动偏股型年化回报</p>
              </div>
            </div>
          </div>
          <div class="flex flex-between label_box ">
            <div>
              <p>任职年限</p>
              <p>8.93年</p>
            </div>
            <div class="margin-l-8 margin-r-8">
              <p>管理规模</p>
              <p>228.84亿</p>
            </div>
            <div>
              <p>重仓行业</p>
              <p>制造业</p>
            </div>
          </div>
        </div>
      </div>
      <div class="tips">本视频不作为基金推介宣传材料</div>
    </div>
    <div class="card_heng animate__animated animate__fadeIn duration none" id="card9">
      <div class="card_main">
        <div class="title">
          <p class="title_l">基金经理运作指数<span>（任职以来）</span></p>
          <p class="title_r">
            <span>基金经理</span><span>同类平均</span><span>沪深300</span>
          </p>
        </div>
        <div class="content_box">
          <div id="chart4" class="chart"></div>
        </div>
      </div>
      <div class="tips">本视频不作为基金推介宣传材料</div>
    </div>
    <div class="card_heng animate__animated animate__fadeIn duration none" id="card10">
      <div class="card_main">
        <div class="title">
          <p class="title_l">基金抗跌能力</p>
          <p class="font-16">近一年</p>
        </div>
        <div class="content_box flex">
          <div>
             <p>最大回撤</p>
             <p><span id="num3">16.47</span>%</p>
           </div>
           <div>
             <p>同类排名（共2685支）</p>
             <p>NO.16</p>
           </div>
        </div>
      </div>
      <div class="tips">本视频不作为基金推介宣传材料</div>
    </div>
    <div class="card_heng animate__animated animate__fadeIn duration none" id="card11">
      <div class="card_main">
        <div class="title">
          <p class="title_l">十大重仓股</p>
        </div>
        <div class="content_box">
          <div id="chart5" class="chart"></div>
        </div>
      </div>
      <div class="tips">本视频不作为基金推介宣传材料</div>
    </div>
    <div class="card_heng animate__animated animate__fadeIn duration none" id="card12">
      <div class="card_main">
        <div class="title">
          <p class="title_l">风险提示</p>
        </div>
        <div class="content_box relative">
          <div class="font-16 text-center">
            <p>上述内容仅供投资者参考，不构成投资建议，据此操作，风险自担。</p>
            <p> 基金的过往业绩并不预示其未来表现，基金管理人管理的其他基金的业绩并 不构成基金业绩表现的保证。</p>
            <p>请您认真阅读《基金合同》等法律文件，结合自身风险承受能力选择合适的产品。 </p>
            <p>市场有风险，投资需谨慎。</p>
          </div>
        </div>
      </div>
      <div class="tips">本视频不作为基金推介宣传材料</div>
    </div>
    <div class="card_heng2 animate__animated animate__fadeIn duration none" id="card13">
      <div class="width-100 height-100">
        <video
          class="end_video"
          src="video/huaanend2.mov"
          muted
          width="100%"
          height="100%"
          ref="endVideo"
        ></video>
      </div>
    </div>
<!-- 
    <div class="card_heng animate__animated animate__fadeIn duration none" id="card14">
      <div class="card_main">
        <div class="title">
          <p class="title_l">基金评级/获奖</p>
        </div>
        <div class="content_box relative">
          <div class="grade_box">
            <p>基金评级</p>
            <p class="flex flex-center">
              <span class="star-five"></span>
              <span class="star-five"></span>
              <span class="star-five"></span>
              <span class="star-five"></span>
              <span class="star-five"></span>
            </p>
          </div>
          <div class="award_box">
            <p>曾获奖项</p>
            <p>“金基金沪港深三年期”</p>
          </div>
        </div>
      </div>
    </div>
    <div class="card_heng animate__animated animate__fadeIn duration none" id="card15">
      <div class="card_main">
        <div class="title">
          <p class="title_l">基金经理观点</p>
        </div>
        <div class="content_box relative">
          <div class="ab_center2 font-16 line_height15 move4">
            <h3>近期观点</h3>
            <p>
              基金经理崔莹近日表示，在流动性边际收缩
              背景下，今年整个市场由估值驱动向盈利驱
              动转变，主要看好以下方向：电动车、科技
              领域国产替代、CXO和医疗服务等。
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="card_heng animate__animated animate__fadeIn duration none" id="card16">
      <div class="card_main">
        <div class="title">
          <p class="title_l">基金管理规模</p>
        </div>
        <div class="content_box relative">
          <div class="ab_center2 text-center content_box_p2">
            <p><span class="font-16 margin-r-8">华安基金管理有限公司</span></p>
            <p class="color_EF622E"><span id="num2"></span>亿</p>
            <p>
              <span class="font-16">NO.15</span
              ><span class="margin-l-8 margin-r-8">/</span><span>170</span>
            </p>
          </div>
        </div>
      </div>
    </div> -->   
  </div>
</template>
<script>
import $ from "jquery";
import { GetParams } from "../utils/common";
import ApiServe from "@/api/index";
export default {
  name: "Template100",
  data() {
    return {
      device_id: null,
      options: {
        useEasing: true, // 过渡动画效果，默认ture
        useGrouping: false, // 千分位效果，例：1000->1,000。默认true
        //separator: ',',   // 使用千分位时分割符号
        decimals: 2, // 小数位分割符号
        prefix: "", // 前置符号
        suffix: "", // 后置符号，可汉字
      },
      chart2: {
        x: ["华安沪港深", "同类平均", "沪深300","业绩比较基准"],
        y: [33.2, 13.8, 11.09,10.21],
      },
      chart3: [
        { value: 61.99, name: "制造业" },
        { value: 2.85, name: "金融业" },
        { value: 1.15, name: "通信服务" },
        { value: 34.01, name: "其他" },
      ],
      chart5: [
        {
          name: "宁德时代",
          value: 7.99,
        },
        {
          name: "晶盛机电",
          value: 5.93,
        },
        {
          name: "中航光电",
          value: 4.91,
        },
        {
          name: "歌尔股份",
          value: 3.62,
        },
        {
          name: "恩捷股份",
          value: 3.38,
        },
        {
          name: "三环集团",
          value: 3.18,
        },
        {
          name: "腾讯控股",
          value: 2.91,
        },
        {
          name: "普洛药业",
          value: 2.87,
        },
        {
          name: "东方财富",
          value: 2.85,
        },
        {
          name: "郑煤机",
          value: 1.93,
        },
      ],
    };
  },
  created() {
    this.device_id = GetParams().device_id || null;
    if (this.device_id) {
      setTimeout(() => {
        ApiServe.recordStart({
          model_id: 100,
          device_id: this.device_id,
          record_time: 94,
          real: 1,
        }).then((res) => {
          this.load();
        });
      }, 5000);
    } else {
      setTimeout(() => {
        this.load();
      }, 1000);
    }
  },
  methods: {
    // 数字滚动
    startNumber(vdom, number, point) {
      let num = new CountUp(vdom, 0, number, point, 2, this.options);
      num.start();
    },
    // 收益走势
    loadChart1(color1 = "#EF622E", color2 = "#7E8090", isShow = true) {
      var myChart = this.$echarts.init(document.getElementById("chart1"));
      var option = {
        xAxis: {
          type: "category",
          data: [
            "2016/03",
            "2017/08",
            "2018/01",
            "2018/09",
            "2019/05",
            "2020/01",
            "2021/09",
          ],
          axisTick: {
            show: false,
          },
          axisLine: {
            lineStyle: {
              color: "#DEDFE6",
            },
          },
          axisLabel: {
            color: "#7E8090",
          },
        },
        grid: {
          left: 19,
          bottom: 30,
        },
        animationDuration: 4000,
        yAxis: {
          type: "value",
          axisLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "#DEDFE6",
            },
          },
        },
        series: [
          {
            data: [100, 120, 110, 160, 180, 170, 240],
            type: "line",
            color: color1,
            showSymbol: false,
            endLabel: {
              show: isShow,
              offset: [-30, -20],
              backgroundColor: "#F5F2F2",
              padding: 10,
              borderRadius: 8,
              color: "#EF622E",
              formatter: function (params) {
                return "389.82%";
              },
              fontSize:16
            },
            lineStyle:{
              width:4
            }
          },
          {
            data: [80, 100, 90, 120, 160, 150, 180],
            type: "line",
            color: color2,
            showSymbol: false,
            endLabel: {
              show: isShow,
              offset: [-30, 20],
              backgroundColor: "#F5F2F2",
              padding: 10,
              borderRadius: 8,
              color: "#7E8090",
              formatter: function (params) {
                return "51.91%";
              },
              fontSize:16
            },
          },
        ],
      };
      myChart.setOption(option);
    },
    // 业绩表现--柱状图
    loadChart2() {
      const colors = ["#EF622E", "#FFBB81", "#38A8FA","#7E8090"];
      var myChart = this.$echarts.init(document.getElementById("chart2"));
      var option = {
        grid: {
          left: 10,
          bottom: 30,
          right: 10,
        },
        xAxis: {
          type: "category",
          data: this.chart2.x,
          axisTick: {
            show: false,
          },
          axisLine: {
            lineStyle: {
              color: "#DEDFE6",
            },
          },
          axisLabel: {
            color: "#7E8090",
            interval: 0,
          },
        },
        animationDuration: 4000,
        yAxis: {
          axisLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "#DEDFE6",
            },
          },
        },
        series: [
          {
            type: "bar",
            barWidth: 20,
            label: {
              show: true,
              position: "top",
              formatter: function ({ value }) {
                return value + "%";
              },
              color: "inherit",
            },
            data: this.chart2.y,
            itemStyle: {
              color: function (val) {
                return colors[val.dataIndex];
              },
            },
          },
        ],
      };
      myChart.setOption(option);
    },
    // 基金持仓情况
    loadChart3() {
      var myChart = this.$echarts.init(document.getElementById("chart3"));
      var option = {
        animationDuration: 4000,
        series: [
          {
            type: "pie",
            radius: ["30%", "60%"],
            startAngle: 120,
            minAngle:30,
            label: {
              color: "inherit",
              formatter: function ({ value, name }) {
                return name + "\n\n" + value + "%";
              },
            },
            data: this.chart3,
            itemStyle: {
              normal: {
                color: function (params) {
                  //自定义颜色
                  var colorList = ["#EF622E", "#C3A178", "#5B72B1", "#aaa"];
                  return colorList[params.dataIndex];
                },
              },
            },
          },
        ],
      };
      myChart.setOption(option);
    },
    // 基金经理运作指数
    loadChart4() {
      var myChart = this.$echarts.init(document.getElementById("chart4"));
      var option = {
        xAxis: {
          type: "category",
          data: [
            "2017/01",
            "2017/08",
            "2018/01",
            "2018/09",
            "2019/05",
            "2020/01",
            "2020/05",
          ],
          axisTick: {
            show: false,
          },
          axisLine: {
            lineStyle: {
              color: "#DEDFE6",
            },
          },
          axisLabel: {
            color: "#7E8090",
          },
        },
        grid: {
          left: 10,
          bottom: 30,
        },
        animationDuration: 3000,
        yAxis: {
          type: "value",
          axisLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "#DEDFE6",
            },
          },
        },
        series: [
          {
            data: [120, 140, 160, 180, 220, 280, 360],
            type: "line",
            name: "华安沪港深",
            color: "#EF622E",
            showSymbol: false,
            endLabel: {
              show: true,
              offset: [-30, -20],
              backgroundColor: "#F5F2F2",
              padding: 10,
              borderRadius: 8,
              color: "#EF622E",
              formatter: function (params) {
                return "159.65%";
              },
              fontSize:16
            },
            lineStyle:{
              width:4
            },
          },
          {
            data: [90, 110, 140, 150, 180, 170, 240],
            type: "line",
            name: "同类平均",
            color: "#C3A178",
            showSymbol: false,
            endLabel: {
              show: true,
              offset: [-30, 0],
              backgroundColor: "#F5F2F2",
              padding: 10,
              borderRadius: 8,
              color: "#C3A178",
              formatter: function (params) {
                return "76.24%";
              },
              fontSize:16
            },
          },
          {
            data: [90, 100, 70, 86, 100, 140, 180],
            type: "line",
            name: "沪深300",
            color: "#7E8090",
            showSymbol: false,
            endLabel: {
              show: true,
              offset: [-30, 20],
              backgroundColor: "#F5F2F2",
              padding: 10,
              borderRadius: 8,
              color: "#7E8090",
              formatter: function (params) {
                return "51.91%";
              },
              fontSize:16
            },
          },
        ],
      };
      myChart.setOption(option);
    },
    // 十大重仓持股
    loadChart5() {
      var myChart = this.$echarts.init(document.getElementById("chart5"));
      // 指定图表的配置项和数据
      let option = {
        legend: {
          show: false,
        },
        series: [
          {
            type: "treemap",
            data: this.chart5,
            left: 16,
            top: 16,
            bottom: 16,
            right: 16,
            //animationDuration: 4000,
            breadcrumb: {
              show: false,
            },
            levels: [
              {
                // color: [
                //   "RGBA(239, 98, 46, 1)",
                //   "RGBA(239, 98, 46, 0.95)",
                //   "RGBA(239, 98, 46, 0.9)",
                //   "RGBA(239, 98, 46, 0.85)",
                //   "RGBA(239, 98, 46, 0.8)",
                //   "RGBA(239, 98, 46, 0.75)",
                //   "RGBA(239, 98, 46, 0.7)",
                //   "RGBA(239, 98, 46, 0.65)",
                //   "RGBA(239, 98, 46, 0.6)",
                //   "RGBA(239, 98, 46, 0.55)",
                //   "RGBA(239, 98, 46, 0.5)",
                // ],
                color:['#EF622E']
              },
            ],
            label: {
              show: true,
              color: "#fff",
              formatter: function (value) {
                return value.name + "\n" + "\n" + value.value + "%";
              },
            },
          },
        ],
      };
      myChart.setOption(option);
    },
    load() {
      this.$refs.bgAudio.volume = 0.4; //背景音乐音量调节（1为最大）
      this.$refs.bgAudio.play(); //背景音乐播放
      this.$refs.audio1.play();
      // this.$refs.video1.play(); //首页的视频播放
      setTimeout(() => {
        $("#card1_heng").addClass("animate__fadeOut");
      }, 3000);
      setTimeout(() => {
        $("#card1_heng").addClass("none");
        $("#card2").removeClass("none");
        this.loadChart1();
        this.$refs.audio2.play();
      }, 4000); //间隔
      setTimeout(() => {
        $(".content_box_p").fadeIn("slow");
      }, 9000);
      setTimeout(() => {
        this.$refs.audio2_1.play();
      }, 9500);
      setTimeout(() => {
        $("#card2").addClass("animate__fadeOut");
      }, 11500);
      setTimeout(() => {
        $("#card2").addClass("none");
        $("#card3").removeClass("none");
        this.loadChart2();
        this.$refs.audio3.play();
      }, 12500); //间隔
      setTimeout(() => {
        $(".title_des1").fadeIn("slow");
      }, 13500);
      // setTimeout(() => {
      //   $(".title_des1").animate({right:'30px'},1000)
      // }, 14500);
      setTimeout(() => {
        $(".title_des1").css("display", "none");
        $(".title_des2").fadeIn("slow");
      }, 15500);
      // setTimeout(() => {
      //   $(".title_des2").animate({right:'30px'},1000)
      // }, 16500);
      setTimeout(() => {
        $(".title_des2").css("display", "none");
        $(".title_des3").fadeIn("slow");
      }, 18000);
      setTimeout(() => {
        $(".title_des3").css("display", "none");
        $('.card3_cardmain').animate({right:'33px'},2000)
      }, 22000);
      setTimeout(() => {
        $('.card3_abcenter').fadeIn("slow");
        this.$refs.audio3_2.play();
      }, 23000);
      setTimeout(() => {
        $("#card3").addClass("animate__fadeOut");
      }, 27000);
      setTimeout(() => {
        $("#card3").addClass("none");
        $("#card4").removeClass("none");
        $(".move_1").animate({ left: "50%", top: "50%" }, 1000);
        this.$refs.audio4.play();
      }, 28000); //间隔
      setTimeout(() => {
        $("#card4").addClass("animate__fadeOut");
      }, 30000);
      setTimeout(() => {
        $("#card4").addClass("none");
        $("#card5").removeClass("none"); 
        this.startNumber("num2", 307.98, 2); 
        this.$refs.audio5.play(); 
      }, 31000); //间隔
      setTimeout(() => {
        $("#card5").addClass("animate__fadeOut");
      }, 38000);
      setTimeout(() => {
        $("#card5").addClass("none");
        $("#card6").removeClass("none");
        this.loadChart3();
        this.$refs.audio6.play();
      }, 39000); //间隔
      setTimeout(() => {
        $("#card6").addClass("animate__fadeOut");
      }, 48000);
      setTimeout(() => {
        $("#card6").addClass("none");
        $("#card7").removeClass("none");
        $(".move_3").animate({ left: "50%", top: "50%" }, 1000);
        this.$refs.audio7.play();
      }, 49000); //间隔
      setTimeout(() => {
        $("#card7").addClass("animate__fadeOut");
      }, 51000);
      setTimeout(() => {
        $("#card7").addClass("none");
        $("#card8").removeClass("none");
        this.startNumber("num1", 20.08, 2);
        this.$refs.audio8.play();
      }, 52000); //间隔
      setTimeout(() => {
        $("#card8").addClass("animate__fadeOut");
      }, 57000);
      setTimeout(() => {
        $("#card8").addClass("none");
        $("#card9").removeClass("none");   
        this.loadChart4();
        this.$refs.audio9.play();
      }, 58000); //间隔
      setTimeout(() => {
        $("#card9").addClass("animate__fadeOut");
      }, 64000);
      setTimeout(() => {
        $("#card9").addClass("none");
        $("#card10").removeClass("none");
        this.startNumber("num3", 16.47, 2); 
        this.$refs.audio10.play();  
      }, 65000); //间隔
      setTimeout(() => {
        $("#card10").addClass("animate__fadeOut");
      }, 71000);
      setTimeout(() => {
        $("#card10").addClass("none");
        $("#card11").removeClass("none");
        this.loadChart5();
        this.$refs.audio11.play();     
      }, 72000); //间隔 
      setTimeout(() => {
        $("#card11").addClass("animate__fadeOut");
      }, 77000);
      setTimeout(() => {
        $("#card11").addClass("none");
        $("#card12").removeClass("none");
         this.$refs.audio12.play();  
      }, 78000); //间隔)
      setTimeout(() => {
        $("#card12").addClass("animate__fadeOut");
      }, 88000);
      setTimeout(() => {
        $("#card12").addClass("none");
        $("#card13").removeClass("none");
        this.$refs.endVideo.play();
      }, 89000); //间隔
      // 总时间 89 + 5秒
      if (this.device_id) {
        setTimeout(() => {
          ApiServe.recordEnd({
            model_id: 100,
            device_id: this.device_id,
            real: 1,
          });
        }, 104000);
      }
    },
  },
};
</script>
<style scoped lang="less">
.max_box {
  background: url("../assets/img/huaanbg.png") no-repeat center center;
  background-size: cover;
  font-size: 12px;
}
.card_heng {
  color: #7e8090;
  font-size: 12px;
  position: absolute;
  left: 100px;
  right: 100px;
  top: 25px;
  bottom: 25px;
  background: rgba(255, 255, 255, 0.6);
  border: 2.6px solid #FFFFFF;
  border-radius: 12px;
  overflow: hidden;
}
.color_EF622E {
  color: #ef622e;
}
.chart {
  height: 100%;
  width: 100%;
}
.tips {
  position: absolute;
  top: 56px;
  right: 10px;
  width: 12px;
  color: #A5A5A5;
}
.ab_center {
  position: absolute;
  left: 19px;
  right: 20px;
  top: 23px;
  bottom: 28px;
  background: rgba(231, 232, 242, 0.9);
}
#card2{
  .ab_center{
    p:nth-of-type(1){
      position: absolute;
      left: 100px;
      top: 84px;
      width: 64px;
      span{
        margin-right: 30px;
      }
    }
    p:nth-of-type(2){
      position: absolute;
      left: 176px;
      top: 66px;
      font-size: 16px;
    }
  }
}
#card3{
  .ab_center{
    // left: 61px;
    left: 11.76%;
    right: 46px;
    top: 68px;
    bottom: 28px;
    background:rgba(231, 232, 242, 1);
    p:nth-of-type(1){
      position: absolute;
      left: 64px;
      top: 50px;
      width: 100px;
    }
    p:nth-of-type(2){
      position: absolute;
      left: 206px;
      top: 28px;
    }
  }
}
.card1_top_box {
  position: absolute;
  top: 55px;
  left: 40px;
  > p:first-of-type {
    font-size: 24px;
    font-weight: bold;
    color: #3c3c3c;
  }
  > p:nth-of-type(2) {
    width: 80px;
    height: 24px;
    margin: 12px 0;
    background: #FFEBEB;
    border-radius: 12px;
    font-size: 16px;
    color: #ef622e;
    font-weight: bold;
    text-align: center;
  }
  > div:first-of-type {
    margin-top: 88px;
  }
  > div {
    margin-top: 25px;
    >div{
      margin-right: 40px;
      text-align: center;
      p:first-of-type {
        color: #7e8090;
      }
      p:nth-of-type(2) {
        width: 80px;
        height: 24px;
        line-height: 24px;
        background: #E6E8F1;
        border-radius: 12px;
        margin: 4px auto;
        font-weight: bold;
        color: #3c3c3c;
      }
    }
  }
}
.card_main {
  position: absolute;
  top: 18px;
  left: 33px;
  right: 33px;
  bottom: 0;
}
#card3 .card_main{
  right: 203px;
  .card3_des{
    position: absolute;
    top: 90px;
    right: -182px;
    width: 144px;
    .card3_num{
      font-size: 20px;
    }
    // .title_des1,.title_des2,.title_des3{
    //   position: absolute;
    //   width: 144px;
    //   right: -200px;
    //   top: 0;
    // }
  }
}
.content_box {
  height: 223px;
  box-shadow: 0px 4px 8px rgba(126, 128, 144, 0.16);
  border-radius: 8px;
  border: 2px solid #F2F3F7;
  margin-top: 11px;
  margin-right: 10px;
  background: rgba(255,255,255,0.8);
  .content_box_p {
    > p:nth-of-type(1) {
      font-size: 16px;
      font-weight: bold;
      color: #7e8090;
    }
    > p:nth-of-type(2) {
      font-size: 16px;
      font-weight: bold;
      color: #ef622e;
      height: 80px;
    }
  }
}
.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  line-height: 30px;
  .title_l {
    height: 30px;
    font-size: 21px;
    color: #7e8090;
    font-weight: bold;
  }
  .title_r {
    height: 30px;
    > span:nth-of-type(1) {
      color: #ef622e;
      margin-right: 20px;
    }
    > span:nth-of-type(1)::before {
      content: "";
      display: inline-block;
      width: 8px;
      height: 2px;
      margin-bottom: 4px;
      margin-right: 4px;
      background: #ef622e;
    }
    > span:nth-of-type(2) {
      color: #7e8090;
    }
    > span:nth-of-type(2)::before {
      content: "";
      display: inline-block;
      width: 8px;
      height: 2px;
      margin-bottom: 4px;
      margin-right: 4px;
      background: #7e8090;
    }
  }
}
#card8 {
  .content_box {
    padding: 0 46px;
    >div:first-of-type{
      margin-top: 42px;
      img {
        width: 60px;
        height: 60px;
      }
    }   
    .name_box {
      font-weight: bold;
      span:nth-of-type(1) {
        color: #3c3c3c;
      }
      span:nth-of-type(2) {
        color: #ef622e;
      }
    }
    .des_box {
      margin-top: 4px;
    }
    .label_box {
      margin-top: 30px;
      > div {
        text-align: center;
        > p:nth-of-type(2) {
          margin-top: 4px;
          color: #3c3c3c;
          width: 80px;
          height: 24px;
          line-height: 24px;
          font-weight: bold;
          border-radius: 12px;
          background: #f5f2f2;
          text-align: center;
        }
      }
    }
  }
}
.move_1,
.move_3 {
  position: absolute;
  left: 0;
  top: 0;
  text-align: center;
  transform: translate(-50%,-50%);
    p {
    padding: 12px 16px;
    background: #7e8090;
    color: #fff;
    font-size: 21px;
    white-space: nowrap;
  }
}
.move4 {
  top: 80%;
}
.large{
  font-size: 56px;
}
#num2 {
  font-size: 66px;
}
#card5{
  .content_box{
    padding-left: 33px;
    >div{
      width: 50%;
      margin-top: 55px;
      >p:nth-of-type(2){
        margin-top: 30px;
        font-size: 60px;
        color: #EF622E;
        .compare{
          font-size: 40px;
          margin-right: 4px;
        }
      }
    }
    >div:nth-of-type(2){
      margin-left: 40px;
    }
  }
}
#card9{
  .card_main{
    .title_l{
      span{
        font-size: 12px;
      }
    }
    .title_r{
        > span:nth-of-type(2) {
          color: #C3A178;
          margin-right: 20px;
        }
        > span:nth-of-type(2)::before {
          content: "";
          display: inline-block;
          width: 8px;
          height: 2px;
          margin-bottom: 4px;
          margin-right: 4px;
          background: #C3A178;
        }
        > span:nth-of-type(3) {
          color: #636679;
        }
        > span:nth-of-type(3)::before {
          content: "";
          display: inline-block;
          width: 8px;
          height: 2px;
          margin-bottom: 4px;
          margin-right: 4px;
          background: #636679;
        }
    }
  }
}
#card10{
  .content_box{
    padding-left: 33px;
    >div{
      width: 50%;
      margin-top: 55px;
      >p:nth-of-type(2){
        margin-top: 30px;
        font-size: 60px;
        color: #636679;
      }
    }
    >div:nth-of-type(2){
      margin-left: 34px;
      >p:nth-of-type(2){
        color: #EF622E;
      }
    }
  }
}
#card12{
  .content_box{
    >div{
      margin-top: 40px;
    }
    p{
      margin: 8px 16px 0 16px;
    }
  }
}
.card_heng2{
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
</style>